<template>
  <ion-page><cyan-page-wrapper
    title="Lista de Votantes Registrados"
    backRoute="/voters"
    nav1="CARGA ELECTORAL"
    nav2="VOTANTES REGISTRADOS"
  >
    <div
      class="banner nivel1 gris"
      v-if="votantes.length"
    >VOTANTES REGISTRADOS ({{ votantes.length }})</div>

    <div class="banner nivel2 gris">
      Nota: durante la jornada electoral, aquellos votantes cuyo voto ya haya sido confirmado en el Punto Cyan no le aparecerán en el listado.
    </div>

    <div v-for="element in votantes" :key="element.id" class="votante">
      <ion-row>
        <ion-col size="12" class="nombre">{{ element.nombre }}
          <ion-button class="ion-hide-md-down ion-float-right" v-if="element.ccStatus != 'ok' && !element.propio" size="small" @click="editData(element.id)">
            <ion-icon :icon="pencil" slot="end"></ion-icon>Editar
          </ion-button>
          <ion-button class="ion-hide-md-up ion-float-right" v-if="element.ccStatus != 'ok' && !element.propio" size="small" @click="editData(element.id)">
            <ion-icon :icon="pencil"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row v-if="element.departamento || element.municipio">
        <ion-col size="12" size-md class="dato" v-if="element.departamento">
          Depto.:
          <span>{{ element.departamento }}</span>
        </ion-col>
        <ion-col size="12" size-md class="dato" v-if="element.municipio">
          Mpio.:
          <span>{{ element.municipio }}</span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-md class="dato">
          DUI:
          <span>{{ element.dui }}</span>
        </ion-col>
        <ion-col size="12" size-md v-if="element.telefono" class="dato">
          Tel:
          <span><a :href="'tel:'+element.telefono">{{ telefonoGuay(element.telefono) }} <ion-icon :icon="call" color="primary"></ion-icon></a> </span>
        </ion-col>
        <ion-col size="12" size-md v-if="element.telefono2" class="dato">
          Tel. 2:
          <span>{{ telefonoGuay(element.telefono2) }}</span>
        </ion-col>
        <ion-col size="12" size-md v-if="element.telefono3" class="dato">
          Tel. 3:
          <span>{{ telefonoGuay(element.telefono3) }}</span>
        </ion-col>
      </ion-row>
      <ion-row v-if="element.email">
        <ion-col size="12" class="dato">
          Email:
          <span>{{ element.email }}</span>
        </ion-col>
      </ion-row>
      <ion-row v-if="element.ccStatus">
        <ion-col size="12" :class="['dato', 'cc-'+element.ccStatus ]">
          <ion-icon :icon="element.ccStatus == 'ok' ? checkmarkCircleOutline : (element.ccStatus == 'rejected' ? warningOutline : helpCircleOutline)"></ion-icon>
          {{ element.ccStatus == 'ok' ? 'Verificado por call center' : (element.ccStatus == 'rejected' ? 'Rechazado por call center' : 'Pendiente de verificar por call center') }}
        </ion-col>
      </ion-row>
    </div>
    <div class="banner nivel1 gris" v-if="!votantes.length">Aún no ha registrado votantes.</div>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  store,
  defineComponent
} from "@/components/CyanPageWrapper.vue";

import { IonRow, IonCol, IonPage, IonIcon, IonButton } from "@ionic/vue";

import {
  warningOutline,
  helpCircleOutline,
  checkmarkCircleOutline,
  call,
  pencil
} from "ionicons/icons";


export default defineComponent({
  name: "VoterList",
  components: {
    IonRow,
    IonPage,
    IonButton,
    IonCol,
    IonIcon,
    CyanPageWrapper
  },
  setup() {
    return {
      warningOutline,
      helpCircleOutline,
      checkmarkCircleOutline,
      call,
      pencil
    }
  },
  data() {
    return {};
  },
  computed: {
    votantes() {
      return store.getters.listaVotantes;
    }
  },
  methods: {
    telefonoGuay(t: string) {
      return t.substr(0, 4) + "-" + t.substr(4);
    },
    editData(t: number) {
      this.$router.push('/editVoter/' + t)
    }
  }
});
</script>

<style scoped>
.nombre {
  font-weight: 500;
  font-size: 120%;
  color: var(--ion-color-primary);
}
.dato {
  color: var(--cyan-dark-grey);
  font-weight: 500;
  padding-left: 1.6em;
  text-indent: -1.6em;
}
.dato span {
  color: var(--ion-color-primary);
  font-weight: normal;
}
.votante {
  margin: 5px 0;
  border: 1px solid var(--cyan-light-grey);
  border-radius: 15px;
  background: white;
  padding: 0 5px;
}

.dato.cc-ok {
  color: var(--ion-color-success);
}

.dato.cc-rejected {
  color: var(--ion-color-danger);
}

.dato.cc-pending {
  color: var(--ion-color-medium);
}

.dato ion-icon {
  font-size: 110%;
  padding: 0 0 0 3px;
  position: relative;
  top: 3px;
}


</style>