
import CyanPageWrapper, {
  store,
  defineComponent
} from "@/components/CyanPageWrapper.vue";

import { IonRow, IonCol, IonPage, IonIcon, IonButton } from "@ionic/vue";

import {
  warningOutline,
  helpCircleOutline,
  checkmarkCircleOutline,
  call,
  pencil
} from "ionicons/icons";


export default defineComponent({
  name: "VoterList",
  components: {
    IonRow,
    IonPage,
    IonButton,
    IonCol,
    IonIcon,
    CyanPageWrapper
  },
  setup() {
    return {
      warningOutline,
      helpCircleOutline,
      checkmarkCircleOutline,
      call,
      pencil
    }
  },
  data() {
    return {};
  },
  computed: {
    votantes() {
      return store.getters.listaVotantes;
    }
  },
  methods: {
    telefonoGuay(t: string) {
      return t.substr(0, 4) + "-" + t.substr(4);
    },
    editData(t: number) {
      this.$router.push('/editVoter/' + t)
    }
  }
});
